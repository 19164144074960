import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneralAPIService implements Resolve<any> {

  years: any[];
  users: any[];
  brands: any[];
  orders: any[];
  vendors: any[];
  banners: any[];
  products: any[];
  customers: any[];
  categories: any[];
  campaigns: any[];
  subcategories: any[];
  manufacturers: any[];

  onYearsChanged: BehaviorSubject<any>;
  onUsersChanged: BehaviorSubject<any>;
  onBrandsChanged: BehaviorSubject<any>;
  onOrdersChanged: BehaviorSubject<any>;
  onVendorsChanged: BehaviorSubject<any>;
  onBannersChanged: BehaviorSubject<any>;
  onProductsChanged: BehaviorSubject<any>;
  onCategoryChanged: BehaviorSubject<any>;
  onCampaignsChanged: BehaviorSubject<any>;
  onCustomersChanged: BehaviorSubject<any>;
  onSubCategoryChanged: BehaviorSubject<any>;
  onManufacturersChanged: BehaviorSubject<any>;

  BusinessTypeID: number = 1;
  ProductPageLimit: number = 10;
  ProductPageNumber: number = 1;

  constructor(private httpResp: HttpClient) { // Set the defaults
    this.onYearsChanged = new BehaviorSubject({});
    this.onUsersChanged = new BehaviorSubject({});
    this.onBrandsChanged = new BehaviorSubject({});
    this.onOrdersChanged = new BehaviorSubject({});
    this.onVendorsChanged = new BehaviorSubject({});
    this.onBannersChanged = new BehaviorSubject({});
    this.onProductsChanged = new BehaviorSubject({});
    this.onCategoryChanged = new BehaviorSubject({});
    this.onCampaignsChanged = new BehaviorSubject({});
    this.onCustomersChanged = new BehaviorSubject({});
    this.onSubCategoryChanged = new BehaviorSubject({});
    this.onManufacturersChanged = new BehaviorSubject({});
  }

  // rootUrlLocal = 'https://apapi.mangotechsoftwares.com'; // *Live Url Old
  rootUrlLocal = 'https://api.partzbuddy.com'; // *Live Url
  // rootUrlLocal = 'http://localhost:3763';


  //#region resolve
  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {

      Promise.all([
        this.getManufacturers(),
        this.getSubCategories(),
        this.getCampaignsList(),
        this.getCategories(),
        this.getCustomers(),
        this.getVendors(),
        this.getProducts(this.ProductPageLimit, this.ProductPageNumber),
        this.getBrands(),
        this.getBanners(),
        this.getOrders(),
        this.getYears(),
        this.getUsers()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }
  //#endregion

  //#region Manufacturers
  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */
  getManufacturers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpResp.get(this.rootUrlLocal + '/api/Products/GetManufacturers?BusinessTypeID=' + this.BusinessTypeID)
        .subscribe((response: any) => {
          this.manufacturers = response;
          this.onManufacturersChanged.next(this.manufacturers);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  //#region SubCategories
  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */
  getSubCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpResp.get(this.rootUrlLocal + '/api/Products/GetSubCategory?BusinessTypeID=' + this.BusinessTypeID)
        .subscribe((response: any) => {
          this.subcategories = response;
          this.onSubCategoryChanged.next(this.subcategories);
          resolve(response);
        }, reject);
    });
  }
  //#endregion 

  //#region Campaigns List
  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */
  getCampaignsList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpResp.get(this.rootUrlLocal + '/api/Custom/CampaignsList?BusinessTypeID=' + this.BusinessTypeID)
        .subscribe((response: any) => {
          this.campaigns = response;
          this.onCampaignsChanged.next(this.campaigns);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  //#region Categories
  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */
  getCategories(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpResp.get(this.rootUrlLocal + '/api/Products/GetCategory?BusinessTypeID=' + this.BusinessTypeID)
        .subscribe((response: any) => {
          this.categories = response;
          this.onCategoryChanged.next(this.categories);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  //#region customers
  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */
  getCustomers(): Promise<any> {
    return new Promise((resolve, reject) => {
      var auth_token = localStorage.getItem("token");
      var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
      this.httpResp.get(this.rootUrlLocal + '/api/Custom/UsersList?BusinessTypeID=' + this.BusinessTypeID, { headers: reqHeaders })
        .subscribe((response: any) => {
          this.customers = response;
          this.onCustomersChanged.next(this.customers);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  //#region vendors
  /**
   * Get categories
   *
   * @returns {Promise<any>}
   */
  getVendors(): Promise<any> {
    return new Promise((resolve, reject) => {
      var auth_token = localStorage.getItem("token");
      var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
      this.httpResp.get(this.rootUrlLocal + '/api/Custom/VendorLocationWithDetails?BusinessTypeID=' + this.BusinessTypeID, { headers: reqHeaders })
        .subscribe((response: any) => {
          this.vendors = response;
          this.onVendorsChanged.next(this.vendors);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  //#region products
  /**
   * Get products
   *
   * @returns {Promise<any>}
   */
  getProducts(limit, page): Promise<any> {
    return new Promise((resolve, reject) => {
      // this.httpResp.get(this.rootUrlLocal + '/api/Products/GetProducts?BusinessTypeID=' + this.BusinessTypeID)
      this.httpResp.get(this.rootUrlLocal + '/api/Products/GetProductsByFilter?BusinessTypeID=' + this.BusinessTypeID + '&limit=' + limit + '&page=' + page)
        .subscribe((response: any) => {
          this.products = response;
          this.onProductsChanged.next(this.products);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  //#region Brands
  /**
  * Resolver
  *
  * @param {ActivatedRouteSnapshot} route
  * @param {RouterStateSnapshot} state
  * @returns {Observable<any> | Promise<any> | any}
  */
  /**
   * Get brands
   *
   * @returns {Promise<any>}
   */
  getBrands(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpResp.get(this.rootUrlLocal + '/api/Products/GetBrands?BusinessTypeID=' + this.BusinessTypeID)
        .subscribe((response: any) => {
          this.brands = response;
          this.onBrandsChanged.next(this.brands);
          resolve(response);
        }, reject);
    });
  }
  //#endregion Brands

  //#region Banners
  /**
  * Resolver
  *
  * @param {ActivatedRouteSnapshot} route
  * @param {RouterStateSnapshot} state
  * @returns {Observable<any> | Promise<any> | any}
  */
  /**
   * Get brands
   *
   * @returns {Promise<any>}
   */
  getBanners(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpResp.get(this.rootUrlLocal + '/api/Custom/GetBanners?BusinessTypeID=' + this.BusinessTypeID)
        .subscribe((response: any) => {
          this.banners = response;
          this.onBannersChanged.next(this.banners);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  //#region Orders
  /**
   * Get orders
   *
   * @returns {Promise<any>}
   */
  getOrders(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpResp.get(this.rootUrlLocal + '/api/Orders/GetOrders?BusinessTypeID=' + this.BusinessTypeID)
        .subscribe((response: any) => {
          this.orders = response;
          this.onOrdersChanged.next(this.orders);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  //#region 
  /**
   * Get orders
   *
   * @returns {Promise<any>}
   */
  getYears(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpResp.get(this.rootUrlLocal + '/api/Products/GetYears?BusinessTypeID=' + this.BusinessTypeID)
        .subscribe((response: any) => {
          this.years = response;
          this.onYearsChanged.next(this.years);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  //#region users list
  /**
   * Get orders
   *
   * @returns {Promise<any>}
   */
  getUsers(): Promise<any> {
    return new Promise((resolve, reject) => {
      var auth_token = localStorage.getItem("token");
      var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
      this.httpResp.get(this.rootUrlLocal + '/api/Custom/UsersList?BusinessTypeID=' + this.BusinessTypeID, { headers: reqHeaders })
        .subscribe((response: any) => {
          this.users = response;
          this.onUsersChanged.next(this.users);
          resolve(response);
        }, reject);
    });
  }
  //#endregion

  userLogIn(userName, Password) {
    var data = 'username=' + userName + '&password=' + Password + '&grant_type=password&BusinessTypeID=' + this.BusinessTypeID;
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.httpResp.post(this.rootUrlLocal + '/token', data, { headers: reqHeaders });
  }

  UserLogOut() {
    var Data = [];

    Data.push({
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Account/LogOut', Data[0], { headers: reqHeaders });
  }

  GetCustomer() {
    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.get(this.rootUrlLocal + '/api/Custom/CustomerDDList?BusinessTypeID=' + this.BusinessTypeID, { headers: reqHeaders });
  }

  GetProduct() {
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetProducts?BusinessTypeID=' + this.BusinessTypeID);
  }

  GetProductByProdID(ProductID) {
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetProductsProductIDIDWise?ItemID=' + ProductID + '&BusinessTypeID=' + this.BusinessTypeID);
  }

  GetProductForm() {
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetProductForm?FormTypeID=1&BusinessTypeID=' + this.BusinessTypeID);
  }

  GetProductFormFeild() {
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetProductForm?FormTypeID=1&BusinessTypeID=' + this.BusinessTypeID);
  }

  DeleteProduct(ID) {
    var Data = [];
    Data.push({
      ItemID: ID,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/DeleteProduct', Data[0], { headers: reqHeaders });
  }

  GetCategory() {
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetCategory?BusinessTypeID=' + this.BusinessTypeID);
  }

  DeleteCategory(ID) {
    var Data = [];
    Data.push({
      DCatID: ID,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/DeleteCategory', Data[0], { headers: reqHeaders });
  }

  SaveImage(Image) {
    return this.httpResp.post(this.rootUrlLocal + '/api/Custom/ImageUpload', Image);
  }

  GetBannerImageUrl(Image) {
    return this.httpResp.post(this.rootUrlLocal + '/api/Custom/BannerImageUpload', Image);
  }

  SaveProduct(Data) {
    var DataArray = [];

    DataArray.push({ BusinessTypeID: this.BusinessTypeID, data: Data });

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/AddProduct', DataArray[0], { headers: reqHeaders });
  }

  AddCategory(Category, Image) {
    var Data = [];

    Data.push({
      CategoryName: Category,
      Image: Image,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/AddCategories', Data[0], { headers: reqHeaders });
  }

  EditCategory(ID, CatName, Image) {
    var DataArray = [];

    DataArray.push({ BusinessTypeID: this.BusinessTypeID, CategoryName: CatName, DCatID: ID, ImageUrl: Image });

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/EditCategory', DataArray[0], { headers: reqHeaders });
  }

  CategoryByCategoryID(ID) {
    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetCategoryIDWise?BusinessTypeID=' + this.BusinessTypeID + '&ID=' + ID, { headers: reqHeaders });
  }

  AddSubCategory(CategoryID, SubCategory, Image) {
    var Data = [];

    Data.push({
      HeadCatID: CategoryID,
      CategoryName: SubCategory,
      Image: Image,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/AddCategories', Data[0], { headers: reqHeaders });
  }

  BrandByBrandID(ID) {
    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetBrandIDWise?BusinessTypeID=' + this.BusinessTypeID + '&ID=' + ID, { headers: reqHeaders });
  }

  AddBrand(Brand, Image) {

    var Data = [];

    Data.push({
      BrandTitle: Brand,
      ImageUrl: Image,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/AddBrands', Data[0], { headers: reqHeaders });
  }

  EditBrand(ID, BrandName, Image) {
    var DataArray = [];

    DataArray.push({ BusinessTypeID: this.BusinessTypeID, BrandTitle: BrandName, BrandID: ID, ImageUrl: Image });

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/EditBrand', DataArray[0], { headers: reqHeaders });
  }

  DeleteBrand(ID) {
    var Data = [];
    Data.push({
      BrandID: ID,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/DeleteBrand', Data[0], { headers: reqHeaders });
  }

  ManufacturerByManufacturerID(ID) {
    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetManufacturerIDWise?BusinessTypeID=' + this.BusinessTypeID + '&ID=' + ID, { headers: reqHeaders });
  }

  AddManufacturers(Manufacturers, Image) {
    var Data = [];

    Data.push({
      ManufacturerName: Manufacturers,
      ImageUrl: Image,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/AddManufacturers', Data[0], { headers: reqHeaders });
  }

  EditManufacturers(ID, ManufacturersName, Image) {
    var DataArray = [];

    DataArray.push({ BusinessTypeID: this.BusinessTypeID, ManufacturerName: ManufacturersName, ID: ID, ImageUrl: Image });

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/EditManufacturer', DataArray[0], { headers: reqHeaders });
  }

  DeleteManufacturers(ID) {
    var Data = [];
    Data.push({
      ID: ID,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/DeleteManufacturer', Data[0], { headers: reqHeaders });
  }

  AddYear(Years) {
    var DataArray = [];

    DataArray.push({ BusinessTypeID: this.BusinessTypeID, Year: Years });

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/AddYears', DataArray[0], { headers: reqHeaders });
  }

  EditYear(ID, Year) {
    var DataArray = [];

    DataArray.push({ BusinessTypeID: this.BusinessTypeID, Year: Year, ID: ID });

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/EditYear', DataArray[0], { headers: reqHeaders });
  }

  DeleteYears(ID) {
    var Data = [];
    Data.push({
      ID: ID,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/DeleteYear', Data[0], { headers: reqHeaders });
  }

  YearByYearID(ID) {
    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetYearIDWise?BusinessTypeID=' + this.BusinessTypeID + '&ID=' + ID, { headers: reqHeaders });
  }

  AddBanner(DeletePrevImage, Image) {
    var DataArray = [];

    DataArray.push({ BusinessTypeID: this.BusinessTypeID, DeletePrevImg: DeletePrevImage, ImageUrls: Image });

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Custom/AddBanner', DataArray[0], { headers: reqHeaders });
  }

  GetDeliveryChargesList() {
    return this.httpResp.get(this.rootUrlLocal + '/api/Custom/DeliveryChargesList?BusinessTypeID=' + this.BusinessTypeID);
  }

  AddDeliveryCharges(description, price) {
    var Data = [];

    Data.push({
      DeliveryCharges: price,
      Description: description,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Custom/AddDeliveryCharges', Data[0], { headers: reqHeaders });
  }

  GetOrderStatus() {
    return this.httpResp.get(this.rootUrlLocal + '/api/Orders/OrderStatuses');
  }

  changeOrderStatus(OrderID, StatusID) {
    var Data = [];

    Data.push({
      StatusID: StatusID,
      OrderID: OrderID,
      BusinessTypeID: this.BusinessTypeID
    })

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Orders/ChangeOrderStatus', Data[0], { headers: reqHeaders });
  }

  AddUser(Name, Email_Phone, Phone, User_Role, CountryCode, Password, Cpassword) {
    var Data = [];

    Data.push({
      EmailPh: Email_Phone,
      Name: Name,
      Password: Password,
      ConfirmPassword: Cpassword,
      Cell: Phone,
      BusinessTypeID: this.BusinessTypeID,
      UserRoles: User_Role,
      CountryCodes: CountryCode
    })
    //console.log(Data[0])
    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Account/RegisterUser', Data[0]);
  }

  AddCustomer(Name, Email_Phone, Phone, User_Type, Password, Cpassword) {
    var Data = [];

    Data.push({
      EmailPh: Email_Phone,
      Name: Name,
      Password: Password,
      ConfirmPassword: Cpassword,
      Cell: Phone,
      BusinessTypeID: this.BusinessTypeID,
      UserTypeID: User_Type
    })

    //var auth_token = localStorage.getItem("token");
    //var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json','Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Account/Register', Data[0]);
  }

  AddVendor(Name, Email_Phone, Phone, User_Type, Password, Cpassword, GoogleAddress, latitude, longitude) {
    var Data = [];

    Data.push({
      EmailPh: Email_Phone,
      Name: Name,
      Password: Password,
      ConfirmPassword: Cpassword,
      Cell: Phone,
      BusinessTypeID: this.BusinessTypeID,
      UserTypeID: User_Type,
      GoogleAddress: GoogleAddress,
      Latitude: latitude,
      Longitude: longitude
    })

    //console.log(Data[0]);
    //var auth_token = localStorage.getItem("token");
    //var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json','Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Account/Register', Data[0]);
  }

  PunchOrder(AccountID, Address, OrderType, DeliveryCharges, TotalAmount, PlatFormID, PaymentTypeID, DiscountAmount, TableData) {
    var Data = [];
    Data.push({
      AccountID: AccountID,
      DeliveryAddress: Address,
      OrderTypeID: OrderType,
      DeliveryCharges: DeliveryCharges,
      TotalAmount: TotalAmount,
      BusinessTypeID: this.BusinessTypeID,
      PlatFormID: PlatFormID,
      DiscountAmount: DiscountAmount,
      PaymentTypeID: PaymentTypeID,
      SaleDetails: TableData
    })

    //console.log(Data[0])

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Orders/PunchOrder', Data[0], { headers: reqHeaders });
  }

  GetCustomerAddress(AccountID) {
    return this.httpResp.get(this.rootUrlLocal + '/api/Orders/GetCustomerDetailsAccountIDWise?AccountID=' + AccountID);
  }

  GetSubCategoryByCategoryID(CatID) {
    var data = 'HeadCatID=' + CatID + '&BusinessTypeID=' + this.BusinessTypeID;
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetSubCategoryHeadCatIDWise?' + data);
  }

  GetUserRole() {
    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.get(this.rootUrlLocal + '/api/Custom/GetUserRoles?BusinessTypeID=' + this.BusinessTypeID, { headers: reqHeaders });
  }

  GetEditProductForm(ItemID) {
    var data = 'ItemID=' + ItemID + '&BusinessTypeID=' + this.BusinessTypeID;
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    return this.httpResp.post(this.rootUrlLocal + '/api/Products/EditProductFormProductIDIDWise?ItemID=' + ItemID + '&BusinessTypeID=' + this.BusinessTypeID, { headers: reqHeaders });
  }

  GetVendors() {
    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.get(this.rootUrlLocal + '/api/Custom/VendorLocationWithDetails?BusinessTypeID=' + this.BusinessTypeID, { headers: reqHeaders });
  }

  GetCampaignType() {
    return this.httpResp.get(this.rootUrlLocal + '/api/Custom/CampaignTypeList?BusinessTypeID=' + this.BusinessTypeID);
  }

  CampaignByCampaignID(ID) {
    return this.httpResp.get(this.rootUrlLocal + '/api/Custom/CampaignsIDWise?CampaignID=' + ID + '&BusinessTypeID=' + this.BusinessTypeID);
  }

  AddCampaign(CampaignName, CampaignTypeID, Description, CampaignValue, StartDate, EndDate) {
    var Data = [];
    Data.push({
      CampaignName: CampaignName,
      CampaignTypeID: CampaignTypeID,
      Description: Description,
      CampaignValue: CampaignValue,
      StartDate: StartDate,
      EndDate: EndDate,
      BusinessTypeID: this.BusinessTypeID
    })

    //console.log(Data[0])

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Custom/CUDCampaign', Data[0], { headers: reqHeaders });
  }

  EditCampaign(CampaignID, CampaignName, CampaignTypeID, Description, CampaignValue, StartDate, EndDate) {
    var Data = [];
    Data.push({
      CampaignID: CampaignID,
      CampaignName: CampaignName,
      CampaignTypeID: CampaignTypeID,
      Description: Description,
      CampaignValue: CampaignValue,
      StartDate: StartDate,
      EndDate: EndDate,
      BusinessTypeID: this.BusinessTypeID
    })

    //console.log(Data[0])

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Custom/CUDCampaign', Data[0], { headers: reqHeaders });
  }

  DeleteCampaign(ID) {
    var Data = [];
    Data.push({
      CampaignID: ID,
      IsDeleted: true,
      BusinessTypeID: this.BusinessTypeID
    })

    //console.log(Data[0])

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Custom/CUDCampaign', Data[0], { headers: reqHeaders });
  }

  ActiveDeactiveCampaign(ID) {
    var Data = [];
    Data.push({
      CampaignID: ID,
      IsDeleted: true,
      BusinessTypeID: this.BusinessTypeID
    })

    //console.log(Data[0])

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Custom/ActiveDeactiveCampaign', Data[0], { headers: reqHeaders });
  }

  AddCommission(CommissionAmount, ItemsID) {
    var Data = [];
    Data.push({
      CommissionAmount: CommissionAmount,
      ItemIDs: ItemsID,
      BusinessTypeID: this.BusinessTypeID
    })

    //console.log(Data[0])

    var auth_token = localStorage.getItem("token");
    var reqHeaders = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${auth_token}` });
    return this.httpResp.post(this.rootUrlLocal + '/api/Custom/AddCommision', Data[0], { headers: reqHeaders });
  }

  GetProductVendorWise(value) {
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetProductsVendorWise?pageNumber=1&pageSize=100&IsPagination=true&AccountID=' + value + '&BusinessTypeID=' + this.BusinessTypeID);
  }

  GetProductCategoryWise(value) {
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetProductsCategoryIDWise?CategoryID=' + value + '&BusinessTypeID=' + this.BusinessTypeID);
  }

  GetHeadCategoryBySubCategoryWise(ID) {
    return this.httpResp.get(this.rootUrlLocal + '/api/Products/GetHeadCategorySubCatIDWise?SubCatID=' + ID + '&BusinessTypeID=' + this.BusinessTypeID);
  }
}
