import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Section',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        icon     : 'apps',
        children : [
            {
                id       : 'dashboards',
                title    : 'Dashboards',
                translate: 'NAV.DASHBOARDS',
                type     : 'item',
                icon     : 'dashboard',
                url  : '/apps/dashboards/analytics'
            },
            {
                id       : 'e-commerce',
                title    : 'Order Managment',
                translate: 'NAV.ECOMMERCE',
                type     : 'collapsable',
                icon     : 'shopping_basket',
                children : [
                    {
                        id        : 'orders',
                        title     : 'Add Order',
                        type      : 'item',
                        url       : '/apps/e-commerce/add-order',
                        exactMatch: true
                    },
                    {
                        id        : 'orderDetail',
                        title     : 'View Order List',
                        type      : 'item',
                        url       : '/apps/e-commerce/orders',
                        exactMatch: true
                    },
                    {
                        id        : 'DeliveryCharges',
                        title     : 'Delivery Charges',
                        type      : 'item',
                        url       : '/apps/e-commerce/delivery-charges',
                        exactMatch: true
                    }
                    // {
                    //     id        : 'orderDetail',
                    //     title     : 'Order Detail',
                    //     type      : 'item',
                    //     url       : '/apps/e-commerce/orders/1',
                    //     exactMatch: true
                    // }
                ]
            },
            {
                id       : 'e-commerce',
                title    : 'Product Managment',
                translate: 'NAV.ECOMMERCE',
                type     : 'collapsable',
                icon     : 'shopping_basket',
                children : [
                    {
                        id        : 'addproducts',
                        title     : 'Add Product',
                        type      : 'item',
                        url       : '/apps/e-commerce/add-product',
                        exactMatch: true
                    },
                    {
                        id        : 'products',
                        title     : 'View Product List',
                        type      : 'item',
                        url       : '/apps/e-commerce/products',
                        exactMatch: true
                    },
                    {
                        id        : 'addcategory',
                        title     : 'Add Category',
                        type      : 'item',
                        url       : '/apps/e-commerce/add-category',
                        exactMatch: true
                    },
                    {
                        id        : 'category',
                        title     : 'View Category List',
                        type      : 'item',
                        url       : '/apps/e-commerce/category',
                        exactMatch: true
                    },
                    {
                        id        : 'addsubcategory',
                        title     : 'Add SubCategory',
                        type      : 'item',
                        url       : '/apps/e-commerce/add-subcategory',
                        exactMatch: true
                    },
                    {
                        id        : 'subcategory',
                        title     : 'View SubCategory List',
                        type      : 'item',
                        url       : '/apps/e-commerce/subcategory',
                        exactMatch: true
                    },
                    {
                        id        : 'addbrand',
                        title     : 'Add Brand',
                        type      : 'item',
                        url       : '/apps/e-commerce/add-brand',
                        exactMatch: true
                    },
                    {
                        id        : 'brand',
                        title     : 'View Brand List',
                        type      : 'item',
                        url       : '/apps/e-commerce/brand',
                        exactMatch: true
                    },
                    {
                        id        : 'addmanufacturers',
                        title     : 'Add Manufacturers',
                        type      : 'item',
                        url       : '/apps/e-commerce/add-manufacturers',
                        exactMatch: true
                    },
                    {
                        id        : 'manufacturers',
                        title     : 'View Manufacturers List',
                        type      : 'item',
                        url       : '/apps/e-commerce/manufacturers',
                        exactMatch: true
                    },
                    {
                        id        : 'addyears',
                        title     : 'Add Years',
                        type      : 'item',
                        url       : '/apps/e-commerce/add-years',
                        exactMatch: true
                    },
                    {
                        id        : 'years',
                        title     : 'View Years List',
                        type      : 'item',
                        url       : '/apps/e-commerce/years',
                        exactMatch: true
                    }
                ]
            },
            {
                id       : 'e-commerce',
                title    : 'App Management',
                translate: 'NAV.ECOMMERCE',
                type     : 'collapsable',
                icon     : 'build',
                children : [
                    {
                        id        : 'banner',
                        title     : 'Add Banner',
                        type      : 'item',
                        url       : '/apps/e-commerce/add-banner',
                        exactMatch: true
                    },
                    {
                        id        : 'banner',
                        title     : 'View Banners List',
                        type      : 'item',
                        url       : '/apps/e-commerce/banner',
                        exactMatch: true
                    },
                    {
                        id        : 'add-commission',
                        title     : 'Add Commission',
                        type      : 'item',
                        url       : '/pages/commission/add-commission',
                        exactMatch: true
                    },
                    {
                        id        : 'update-commission',
                        title     : 'Update Commission',
                        type      : 'item',
                        url       : '/pages/commission/update-commission',
                        exactMatch: true
                    },
                    {
                        id        : 'add-campaign',
                        title     : 'Add Campaign',
                        type      : 'item',
                        url       : '/pages/campaign/add-campaign',
                        exactMatch: true
                    },
                    {
                        id        : 'view-campaign',
                        title     : 'View Campaign',
                        type      : 'item',
                        url       : '/pages/campaign/view-campaign',
                        exactMatch: true
                    }
                ]
            },
            {
                id       : 'e-commerce',
                title    : 'Customer Management',
                translate: 'NAV.ECOMMERCE',
                type     : 'collapsable',
                icon     : 'people_outline',
                children : [
                    {
                        id        : 'customer',
                        title     : 'Add Customer',
                        type      : 'item',
                        url       : '/pages/customer/add-customer',
                        exactMatch: true
                    },
                    {
                        id        : 'customerlist',
                        title     : 'View Customer',
                        type      : 'item',
                        url       : '/pages/customer/view-customer',
                        exactMatch: true
                    }
                ]
            },
            {
                id       : 'e-commerce',
                title    : 'Vendor Management',
                translate: 'NAV.ECOMMERCE',
                type     : 'collapsable',
                icon     : 'people_outline',
                children : [
                    {
                        id        : 'products',
                        title     : 'Add Vendor',
                        type      : 'item',
                        url       : '/pages/vendor/add-vendor',
                        exactMatch: true
                    },
                    {
                        id        : 'productDetail',
                        title     : 'View Vendor',
                        type      : 'item',
                        url       : '/pages/vendor/view-vendor',
                        exactMatch: true
                    }
                ]
            },
            {
                id      : 'page-layouts',
                title   : 'User Management',
                type    : 'collapsable',
                icon    : 'view_quilt',
                children: [
                    {
                        id   : 'user',
                        title: 'Add User',
                        type : 'item',
                        url  : '/pages/user/add-user',
                        exactMatch: true
                    },
                    {
                        id        : 'user',
                        title     : 'View Users',
                        type      : 'item',
                        url       : '/pages/user/user-list',
                        exactMatch: true
                    }
                    // {
                    //     id      : 'carded',
                    //     title   : 'Users',
                    //     type    : 'collapsable',
                    //     children: [
                    //         {
                    //             id   : 'full-width-1',
                    //             title: 'Add New User',
                    //             type : 'item',
                    //             url  : '/pages/user/add-user'
                    //         },
                    //         {
                    //             id   : 'full-width-2',
                    //             title: 'User List',
                    //             type : 'item',
                    //             url  : '#'
                    //         }
                    //     ]
                    // }
                ]
            },
        ]
    }
];
